import React, {useContext, useMemo} from "react";
import { Table } from "../layout/Table";
import { Context } from "../../utils/ESignatureContext";

type Props = {
  confirmation?: boolean;
};
export const Table2: React.FC<Props> = ({ confirmation }) => {

  const tableName = "表２";
  const tableCategoryNames = ["臓器・部位", "検査項目"];

  const { state } = useContext(Context);
  const shokaiAppFrmCreateDate =  state?.dataToDisplay?.ShokaiAppFrmCreateDate;
  console.log("state in Table2: " + JSON.stringify(state, null, 2));
  if (!shokaiAppFrmCreateDate) {
    console.error("ShokaiAppFrmCreateDate is missing or not set in the context");
  }

  const shokaiAppFrmCreateDateObj = useMemo(() => 
    new Date(shokaiAppFrmCreateDate),
    [shokaiAppFrmCreateDate]
  );

  const isAfterReferenceDate = useMemo(() => 
    shokaiAppFrmCreateDateObj > new Date("2025-03-31"),
    [shokaiAppFrmCreateDateObj]
  );

  const tableCategoryDetails = [
    "心臓・脳・甲状腺・肺・胃腸・肝臓・腎臓・すい臓・胆のう・前立腺・子宮・乳房",
    `心電図・血圧・尿・便・血液・眼底・${isAfterReferenceDate ? '眼圧・レントゲン（X線）・内視鏡・超音波（エコー）' : 'レントゲン・内視鏡・超音波'}・CT・MRI・PET・マンモグラフィー`,
  ];
  
  return (
    <Table
      tableName={tableName}
      categoryNames={tableCategoryNames}
      categoryDetails={tableCategoryDetails}
      confirmation={confirmation}
      categorized={true}
    />
  );
};