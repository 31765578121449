import React, {useContext, useMemo} from "react";
import { Table } from "../layout/Table";
import { Context } from "../../utils/ESignatureContext";

type Props = {
  confirmation?: boolean;
};
export const Table1: React.FC<Props> = ({ confirmation }) => {
  const tableName = "表１";
  const tableCategoryNames = [
    "心臓・血圧",
    "脳・精神・神経",
    "肺・気管支",
    "胃腸・すい臓",
    "肝臓・胆のう",
    "腎臓・尿管",
    "目・耳・鼻",
    "ガン・しゅよう",
    "婦人科系",
    "その他",
  ];
  
  const { state } = useContext(Context);
  const shokaiAppFrmCreateDate =  state?.dataToDisplay?.ShokaiAppFrmCreateDate;
  console.log("state in Table1 : " + JSON.stringify(state, null, 2));
  if (!shokaiAppFrmCreateDate) {
    console.error("ShokaiAppFrmCreateDate is missing or not set in the context");
  }

  const shokaiAppFrmCreateDateObj = useMemo(() => 
    new Date(shokaiAppFrmCreateDate),
    [shokaiAppFrmCreateDate]
  );

  const isAfterReferenceDate = useMemo(() => 
    shokaiAppFrmCreateDateObj > new Date("2025-03-31"),
    [shokaiAppFrmCreateDateObj]
  );

  const tableCategoryDetails = [
    "狭心症・心筋こうそく・心臓弁膜症・先天性心臓病・心筋症・不整脈（ペースメーカー装着を含む）・高血圧症・大動脈瘤",
    "脳卒中（脳出血・脳こうそく・くも膜下出血）・てんかん・知的障がい・自律神経失調症・統合失調症・そう病・双極性障がい（そううつ病）・うつ病・神経症・パニック障がい・適応障がい・不眠症・認知症・パーキンソン病・アルコール依存症",
    "ぜんそく・慢性気管支炎・気管支拡張症・肺気腫・肺結核・じん肺・慢性閉塞性肺疾患（COPD)・睡眠時無呼吸症候群",
    "胃かいよう・十二指腸かいよう・かいよう性大腸炎・クローン病・すい臓炎",
    "肝炎（ウイルスキャリアを含む）・肝硬変・肝機能障がい・胆石・胆のう炎",
    "腎炎・ネフローゼ・腎不全・腎のう胞・腎臓結石・尿管結石・前立腺の病気",
    "白内障・緑内障・ぶどう膜炎・角膜の病気・網膜の病気・中耳炎・蓄膿症",
    "ガン・肉腫・白血病・しゅよう・上皮の異形成、異型・ポリープ・リンパ腫",
    `子宮筋腫・子宮内膜症・卵巣のう腫・乳腺症・${isAfterReferenceDate ? '' : '不妊症・'}妊娠、分娩に伴う異常（帝王切開含む）`,
    `糖尿病・関節リウマチ・こうげん病・貧血症・紫斑病・甲状腺の病気・椎間板ヘルニア・痔・脂質異常症（高脂血症）・高尿酸血症（痛風）${isAfterReferenceDate ? '・不妊症' : ''}`,
  ];

  return (
    <Table
      tableName={tableName}
      categoryNames={tableCategoryNames}
      categoryDetails={tableCategoryDetails}
      confirmation={confirmation}
      categorized={true}
    />
  ); 
};
